import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';

import Search from '@svg/search.svg';

const SearchBox = ({ currentRefinement, refine, setQuery, initial }) => {
  React.useEffect(() => {
    initial && refine(initial);
  }, []);
  return (
    <div className='relative bg-sand border-b border-teal flex items-center justify-between mt-8 md:mt-0'>
      <input
        type='text'
        value={currentRefinement}
        onChange={(event) => {
          refine(event.currentTarget.value);
          setQuery && setQuery(event.currentTarget.value);
        }}
        placeholder='Search Here'
        className='text-teal blockH4 focus:outline-none bg-sand border-0 focus:shadow-none form-input focus:ring-0 p-0 pb-3 lg:pb-6 placeholder:text-teal placeholder:blockH4 w-full'
      />
      <Search className='w-6 h-6 lg:w-10 lg:h-10' />
    </div>
  );
};

export const CustomSearchBox = connectSearchBox(SearchBox);
