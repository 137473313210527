import React from 'react';
import { Link } from 'gatsby';
import { connectHits } from 'react-instantsearch-dom';

const Hit = ({
  slug,
  title,
  excerpt,
  name,
  dateRange,
  date,
  seo,
  position,
  type,
}) => {
  return (
    <div className='pb-5 md:pb-10 border-b border-[#9A9A9A] flex flex-col gap-y-2 md:gap-y-4 text-teal relative z-10'>
      <span className='blockH6 block'>{title || name}</span>
      {(dateRange || date || position) && (
        <span className='block font-bold blockH8 capitalize'>
          {dateRange || date || position}
        </span>
      )}
      {(excerpt || seo?.metaDesc) && (
        <p className='richH6 block'>{excerpt || seo?.metaDesc}</p>
      )}
      <div className='mt-2 md:mt-5 flex gap-x-3 items-center'>
        <Link
          to={`/${slug?.current}`}
          className='btn-standard bg-teal bg-opacity-10'>
          Tell me more
        </Link>
        {dateRange && (
          <Link
            to={`/${slug?.current}#tickets`}
            className='btn-standard bg-teal bg-opacity-10'>
            Buy tickets
          </Link>
        )}
      </div>
    </div>
  );
};

const Hits = ({ hits, query }) => {
  return (
    <div className='mt-12 md:mt-24 flex flex-col gap-y-5 md:gap-y-10'>
      {query && (
        <span className='block mb-3 md:mb-6 text-grey blockH8'>
          {hits?.length} Results for "{query}"
        </span>
      )}
      {hits.map((hit) => (
        <Hit {...hit} />
      ))}
    </div>
  );
};

export const BlogHits = connectHits(Hits);
