import React from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import {
  InstantSearch,
  connectStateResults,
  Configure,
} from 'react-instantsearch-dom';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { CustomSearchBox } from '@components/search/customSearchBox';
import { BlogHits } from '@components/search/blogHits';
import { searchClient } from '@lib/algolia';

const ExploreSlider = loadable(() =>
  import('../components/pageModules/modules/exploreSlider'),
);

const SearchPortalTemplate = ({ data: { page }, location }) => {
  const { seo } = page || {};
  const [query, setQuery] = React.useState(location?.state?.value || '');

  return (
    <Layout>
      <PageMeta {...seo} />
      <div className='py-space-l px-gutter md:mx-16 min-h-screen relative z-10'>
        <InstantSearch searchClient={searchClient} indexName='pages'>
          <Configure hitsPerPage={600} />
          <CustomSearchBox
            placeholder='Search'
            setQuery={setQuery}
            initial={location?.state?.value}
          />
          <BlogHits query={query} />
        </InstantSearch>
      </div>

      {/* Module at bottom of page */}
      <ExploreSlider className='pt-space-m pb-space-m' />
    </Layout>
  );
};

export default SearchPortalTemplate;

export const simpleSearchPortalQuery = graphql`
  query standardSearchPortalQuery($slug: String!) {
    page: sanitySearchPortal(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      title
    }
  }
`;
